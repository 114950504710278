import React from 'react';
import ImageCard from './core/ImageCard';
import {generateRange} from '../helpers/common';
import {v1 as uuidv1} from 'uuid';

export default function HomeInteriors(){
    return (
        <React.Fragment>
            <h1 className={"m-5 text-teal-600 font-bold text-2xl"}>Home Interiors</h1>
            <hr className={"border-dashed bg-teal-400 m-5"}></hr>
            <div className={"flex flex-wrap"}>
                <div className={"flex-auto"}>
                    <ImageCard imagePath={{source: `img/homeinteriors/1.jpg`, alt: ''}}></ImageCard>
                </div>
                <div className={"flex-auto"}>
                    <ImageCard imagePath={{source: `img/homeinteriors/2.jpg`, alt: ''}}></ImageCard>
                </div>
                
                { generateRange(3, 17).map((item) => 
                    <div key={uuidv1()} className={"flex-auto"}>
                        <ImageCard imagePath={{source: `img/homeinteriors/${item}.jpeg`, alt: ''}}></ImageCard>
                    </div>
                )}
            </div>
        </React.Fragment>
    )
}