import React, {useState} from 'react';
import {Link} from 'react-router-dom'

export default function Navbar({ websiteTitle= 'suffaconstructions' }){
    const [isExpanded, toggleExpansion] = useState(false)

    return(
        <div>
            <nav className={'flex justify-between flex-wrap bg-teal-500 pt-6 px-6 pb-6 md:pb-1'}>
                <Link to="/">
                    <div className="flex md:items-center md:flex-shrink-0 text-white md:mr-6">
                        <svg className="hidden  fill-current h-8 w-8 mr-2" width="54" height="54" viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 22.1c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05zM0 38.3c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05z"/></svg>
                        <span className="font-semibold text-xl tracking-tight">{ websiteTitle}</span>
                    </div>
                    <p className={"text-sm text-white"}>Planing, Designing &amp; Contracting</p>
                </Link>
                <div className="block lg:hidden">
                    <button onClick={() => toggleExpansion(!isExpanded)} className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white">
                        <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
                    </button>
                </div>
                <div className={`${ isExpanded ? `flex` : `hidden` } w-full block  lg:flex lg:items-center lg:w-auto`}>
                    <div className="text-sm lg:flex-grow">
                        <Link to={`/`} className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
                            Home
                        </Link>
                        <Link to={`/contact-us`} className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
                            Contact us
                        </Link>
                    </div>  
                </div>
            </nav>
        </div>
    );
}
