import React from 'react';


export default function ImageCard({
    imagePath={ source:'img/default-image-card-holder.jpg', alt: 'Alt' }
}){
    return (
        <div className={'mx-2 my-2 md:my-5 md:mx-5'}>
            <div className={"h-64 w-full max-w-full rounded overflow-hidden shadow-lg bg-white md:w-64"}>
                <img  className={"w-full h-full sm:w-full md:w-full md:h-full"} src={imagePath.source} alt={imagePath.alt} />
            </div>
        </div>
    )
}