import React from 'react';
import Navbar from './Navbar'

export default function Header(){
    return(
        <header>
            <Navbar websiteTitle="Suffaconstruction"></Navbar>
        </header>
    )
}
