import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Header from "./Header";
import Home from "./Home";
import Footer from "./Footer";
import DesignDrawings from "./DesignDrawings";
import HomeInteriors from "./HomeInteriors";
import LarsenAndTurbo from "./LarsenAndTurbo";
import Residential from "./Residential";
import { Contact } from './Contact';

function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <Router>
        <Header></Header>
        <main className={'flex-1'}>{ mapRoutes() }</main>
        <Footer></Footer>
    </Router>
    </div>
  );
}

function mapRoutes() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/home-interiors" component={HomeInteriors} />
      <Route exact path="/design-drawings" component={DesignDrawings} />
      <Route exact path="/larsen-and-turbo" component={LarsenAndTurbo} />
      <Route exact path="/residential" component={Residential} />
      <Route exact path="/contact-us" component={Contact} />
      <Route exact path="*" component={Home} />
    </Switch>
  );
}


export default App;
