import React from 'react';
import ImageCardWithInfo from './core/ImageCardWithInfo';
import ImageCard from './core/ImageCard';
import ConstructionSvgPath from '../assets/icons/construction.svg';
import DrawingSvgPath from '../assets/icons/drawing.svg';
import InteriorSvgPath from '../assets/icons/interiors.svg';
import PlanApprovalSvgPath from '../assets/icons/plan-approval.svg';

export default function Home(){
    return (
        <div className="mt-5">
            <div  id="Our Service">
                <div className={""}>
                    <h1 className={"text-center font-semibold text-teal-600 text-2xl"}>Our Services</h1>
                    <div class="flex flex-wrap">
                        <div className={"m-4 rounded bg-white shadow-lg flex-auto max-w-full md:max-w-sm"}>
                                <ImageCard imagePath={{source: ConstructionSvgPath, alt: ''}}></ImageCard>
                                <div className={"px-6 py-4"}>
                                    <div className={"font-bold text-teal-700 text-sm mb-2 uppercase"}>Construction</div>
                                </div>
                        </div>
                        <div className={"m-4 rounded bg-white shadow-lg flex-auto max-w-full md:max-w-sm"}>
                                <ImageCard imagePath={{source: DrawingSvgPath, alt: ''}}></ImageCard>
                                <div className={"px-6 py-4"}>
                                    <div className={"font-bold text-teal-700 text-sm mb-2 uppercase"}>Structural drawing 2d &amp; 3D designs</div>
                                </div>
                        </div>
                        <div className={"m-4 rounded bg-white shadow-lg flex-auto max-w-full md:max-w-sm"}>
                                <ImageCard imagePath={{source: InteriorSvgPath, alt: ''}}></ImageCard>
                                <div className={"px-6 py-4"}>
                                    <div className={"font-bold text-teal-700 text-sm mb-2 uppercase"}>Interiors</div>
                                </div>
                        </div>
                        <div className={"m-4 rounded bg-white shadow-lg flex-auto max-w-full md:max-w-sm"}>
                                <ImageCard imagePath={{source: PlanApprovalSvgPath, alt: ''}}></ImageCard>
                                <div className={"px-6 py-4"}>
                                    <div className={"font-bold text-teal-700 text-sm mb-2 uppercase"}>Plan approval</div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"mt-10"} id="Our Project">
                <div className={""}>
                    <h1 className={"text-center font-semibold text-teal-600 text-2xl"}>Our Projects</h1>
                </div>
                <div className="flex flex-wrap justify-between">
                    <div className={"flex-auto max-w-full md:max-w-sm"}>
                        <ImageCardWithInfo title="L&amp;T"
                            description=""
                            imagePath={{source: "img/l-and-t/12.jpg", alt: ""}}
                            url="larsen-and-turbo"
                        ></ImageCardWithInfo>
                    </div>
                    <div className={"flex-auto max-w-full md:max-w-sm"}>
                        <ImageCardWithInfo title="Residential"
                            description=""
                            imagePath={{source: "img/residential/salem-attur.jpg", alt: ""}}
                            url="residential"
                        ></ImageCardWithInfo>
                    </div>
                    <div className={"flex-auto max-w-full md:max-w-sm"}>
                        <ImageCardWithInfo title="Home Interiors"
                            description=""
                            imagePath={{source: "img/homeinteriors/1.jpg", alt: ""}}
                            url="home-interiors"
                        ></ImageCardWithInfo>
                    </div>
                    <div className={"flex-auto max-w-full md:max-w-sm"}>
                        <ImageCardWithInfo title="2D &amp; 3D Designs"
                            description=""
                            imagePath={{source: "img/2d3ddrawings/1.jpg", alt: ""}}
                            url="design-drawings"
                        ></ImageCardWithInfo>
                    </div>
                </div>
            </div>
        </div>
    )
}