import React from 'react';
import GoogleMapReact from 'google-map-react';
import LocationSvg from '../assets/icons/location-outline.svg';
import CallSvg from '../assets/icons/call-outline.svg';
import MailSvg from '../assets/icons/mail-outline.svg';

const MarkerComponent = ({ text }) => <div><img className="h-6" src={LocationSvg} alt='location'/></div>;

function Contact(){
    return(
        <div className="flex m-10 md:justify-around flex-col md:flex-row">
            <div className="flex-col">
                <div className="flex mb-6">
                    <div>
                        <label>
                            <img className="h-6" src={LocationSvg} alt='location'/>
                        </label>
                    </div>
                    <div className="ml-3">
                        <address>
                        No 23/14<br/>
                        Thillai nayagam 5th Street<br/>
                        perambur chennai -600011<br/>
                        near mosque
                        </address>
                    </div>
                </div>

                <div className="md:flex mb-6">
                    <div>
                        <label>
                            <img className="h-6" src={CallSvg} alt='call'/>
                        </label>
                    </div>
                    <div class="ml-3">
                        <div><a href="tel:+919176147579">9176147579</a></div>
                        <div><a href="tel:+919176147579">9840773809</a></div>
                    </div>
                </div>


                <div className="md:flex mb-6">
                    <div>
                        <label>
                            <img className="h-6" src={MailSvg} alt='mail'/>
                        </label>
                    </div>
                    <div className="ml-3">
                        <span>syedshameel15@gmail.com</span>
                    </div>
                </div>
            </div>
        
            <div className="flex h-64 w-64">
                <GoogleMapReact
                        bootstrapURLKeys={{ key: 'AIzaSyCe-92uYhLRULgOBvUsMwOzgdb0W-Wjteg' }}
                        defaultCenter={{
                            lat: 13.115087509155273,
                            lng: 80.23636627197266
                        }}
                        defaultZoom={19}
                    >
                    <MarkerComponent
                    lat={13.115087509155273}
                    lng={80.23636627197266}
                    text="Suffa Construction"
                    />
                </GoogleMapReact>
            </div>
        </div>
    );
}

export {Contact};