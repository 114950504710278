import React from 'react';
import ImageCard from './core/ImageCard';
import {generateRange} from '../helpers/common';

export default function DesignDrawings(){
    return (
        <React.Fragment>
            <h1 className={"m-5 text-teal-600 font-bold text-2xl"}>Design Drawings</h1>
            <hr className={"border-dashed bg-teal-400 m-5"}></hr>
            <div className={"flex flex-wrap"}>
                { generateRange(1, 7).map((item) => 
                    <div className={"flex-auto"}>
                        <ImageCard imagePath={{source: `img/2d3ddrawings/${item}.jpg`, alt: ''}}></ImageCard>
                    </div>
                )}
            </div>
        </React.Fragment>
    )
}