import React from 'react';
import {v1 as uuidv1} from 'uuid';
import ImageCard from './core/ImageCard';


function projects(){
    return [
        { img: 'arcort-kalavai.jpg', location: 'arcort' },
        { img: 'arcort-kalavai-2.jpg', location: 'arcort'},
        { img: 'madavaram.jpg', location: 'madavaram' },
        { img: 'pulintope.jpg', location: 'pulintope' },
        { img: 'salem-1.jpg', location: 'salem' },
        { img: 'salem-2.jpg', location: 'salem' },
        { img: 'salem-attur.jpg', location: 'salem' },
        { img: 'thiru-vika-nagar.jpg', location: 'thiru-vika nagar' }
    ]
}

export default function Residential(){
    return (
        <React.Fragment>
            <h1 className={"m-5 text-teal-600 font-bold text-2xl"}>Residential</h1>
            <hr className={"border-dashed bg-teal-400 m-5"}></hr>
            <div className={"flex flex-wrap"}>
                {
                    projects().map((project) => (
                        <div key={uuidv1()} className={"m-4 rounded bg-white shadow-lg flex flex-col flex-auto"}>
                            <ImageCard imagePath={{source: `img/residential/${project.img}`, alt: ''}}></ImageCard>
                            <div className={"px-6 py-4"}>
                                <div className={"font-bold text-teal-700 text-xl mb-2 uppercase"}>{project.location}</div>
                            </div>
                        </div>)
                    )
                }
            </div>
        </React.Fragment>
    )
}