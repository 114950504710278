import React from 'react';
import ImageCard from './core/ImageCard';
import {generateRange} from '../helpers/common';
import {v1 as uuidv1} from 'uuid';

export default function LarsenAndTurbo(){
    return (
        <React.Fragment>
            <h1 className={"m-5 text-teal-600 font-bold text-2xl"}>L&amp;T</h1>
            <hr className={"border-dashed bg-teal-400 m-5"}></hr>
            <div className={"flex flex-wrap"}>
                <div className={"flex-auto"}>
                    <ImageCard imagePath={{source: `img/l-and-t/1.jpeg`, alt: ''}}></ImageCard>
                </div>
                { generateRange(2, 12).map((item) =>
                    <div key={uuidv1()} className={"flex-auto"}> 
                        <ImageCard imagePath={{source: `img/l-and-t/${item}.jpg`, alt: ''}}></ImageCard>
                    </div>
                )}
            </div>
        </React.Fragment>
    )
}