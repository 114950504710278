import React from 'react';
//import {history} from 'react-router-dom'

function redirect(url='projects'){
    // <Redirect to={url} /> --> Not working
    //history.push(url);
    return window.location.href = url;
}

export default function HrefButton({text="View", url=""}){
    return (
        <button 
            type="button"
            className={"bg-teal-500 text-white m-3 px-3 py-2 rounded"}
            onClick={() => redirect(url)}
        >
            <span className={"text-sm md:text-md"}>{text}</span>
        </button>
    );
}