import React from 'react';
import HrefButton from './HrefButton';

export default function ImageHolderCard({
    title='Title', description='Description',
    imagePath={ source:'img/default-image-card-holder.jpg', alt: 'Alt' }, url="/"
}){
    return (
        <div className={'my-5 mx-5'}>
            <div className={"w-full rounded overflow-hidden shadow-lg bg-white"}>
                <img className={"h-64"} src={imagePath.source} alt={imagePath.alt} />
                <div className={"px-6 py-4"}>
                    <div className={"font-bold text-teal-700 text-sm mb-2"}>{title}</div>
                    <p className={"text-gray-700 text-base"}>
                        { description }
                    </p>
                </div>
                <div className={'flex justify-end'}>
                    <HrefButton url={url} text="More"></HrefButton>
                </div>
            </div>
        </div>
    )
}