import React from 'react';

export default function Footer(){
    return(
       <footer className={'flex bg-teal-600 justify-between'}>
           <span className={'p-6 text-white font-light text-sm'}>&copy; suffaconstruction    2019</span>
           <span className={'p-6 text-white font-light text-sm'}>Crafted By <a href="https://vinaykumar.co">Vinay</a></span>
       </footer>
    )
}
